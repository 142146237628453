import React from "react"
import { graphql } from "gatsby"
import { media, styled } from "../components/mediaCSS"

import Img from "gatsby-image"
import Layout from "../components/layout"
import Map from "../components/Map"
import SEO from "../components/seo"
import { FaCar, FaPhone, FaShoppingCart } from "react-icons/fa"
import StoreHours from "../components/storeHours"
import OrderButtons from "../components/orderButtons"

const ID_CLIENTS = 55

const OrderHolder = styled.div`
  margin: 40px 0px;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  > * {
    width: 30%;
    ${media.tablet_desktop` width: 15%; `}

    height: 140px;
    background: #444444;
    border: 2px solid black;
    margin: 0px 1% 10px;
    text-align: center;
    color: white;
    font-size: 20px;
    border-radius: 5px;
    overflow: hidden;
  }

  .fa {
    margin-top: 20px;
    font-size: 60px;
  }

  .foodImage {
    height: 100%;
  }
`

const AddressHolder = styled.a`
  text-align: center;
  font-size: 40px;
  margin: 30px 0px;
`

const StoreHoursContainer = styled.div`
  margin-bottom: 20px;
  text-align: center;
`

export default ({ data }) => {
  const { favor, cavier, doordash, grubhub, ubereats } = data

  const venue = data.allVenue.nodes.find(v => v.ID_clients === ID_CLIENTS)
  const location = { latitude: venue.latitude, longitude: venue.longitude }

  const ESdoordash = venue.externalServices.find(es => es.name === "doordash")
  const EScavier = venue.externalServices.find(es => es.name === "cavier")
  const ESgrubhub = venue.externalServices.find(es => es.name === "grubhub")
  const ESfavor = venue.externalServices.find(es => es.name === "favor")
  const ESubereats = venue.externalServices.find(es => es.name === "ubereats")
  const ESdoordashstorefront = venue.externalServices.find(
    es => es.name === "doordash-storefront"
  )

  return (
    <Layout>
      <SEO title={venue.venueNameShort} />

      <OrderButtons ID_clients={ID_CLIENTS}></OrderButtons>

      <AddressHolder
        href={`//maps.google.com/?q=${venue.address1},${venue.address2}`}
        target="_blank"
      >
        <div>{venue.address1}</div>
        <div>{venue.address2}</div>
      </AddressHolder>

      <StoreHoursContainer>
        <h3>Store Hours:</h3>
        <StoreHours hours={venue.hours}></StoreHours>
      </StoreHoursContainer>

      <Map locations={[location]}></Map>
    </Layout>
  )
}

export const query = graphql`
  query {
    ubereats: file(relativePath: { eq: "food_ubereats.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    favor: file(relativePath: { eq: "food_favor.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    cavier: file(relativePath: { eq: "food_cavier.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    grubhub: file(relativePath: { eq: "food_grubhub.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    doordash: file(relativePath: { eq: "food_doordash.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    allVenue {
      nodes {
        ID_clients
        address1
        address2
        venueName
        venueNameShort
        longitude
        latitude
        sitePath
        phone
        fullSiteUrl
        hours {
          CloseTime
          DayOfWeek
          OpenTime
        }
        externalServices {
          name
          url
        }
      }
    }
  }
`
